.switchStyle {
    display: flex;
    align-items: center;
    margin: 5px 15px;
}

.switchStyle::after {
    content: '';
    display: inline-block;
    width: 20px;
    height: 20px;
    background-color: #c1c1c1;
    border-radius: 3px;
    margin-left: 10px;
}
